import Axios from 'axios'

const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN

export const getPortefeuille = async (token: string,userEmail:string, year=2025) => {
    try {
        const response = await Axios.get(`${SERVER_APP_DOMAIN}api/portefeuille?userEmail=${userEmail}&year=${year}`,
            {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })

        return response.data
    } catch (error) {
        console.error('Erreur lors de la requête :', error)
        throw error
    }
}