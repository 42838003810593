import React, {useEffect, useState} from 'react';
import Autocomplete from 'react-google-autocomplete';
import swal from 'sweetalert';
import {Close} from '@mui/icons-material'
import {getPostalCodeByCity} from '../../api/Ville/Ville';
import {addCityProfileVisiteur} from '../../api/ProfileVisiteur/AddCityProfileVisiteur';
interface Ville {
    nom: string;
    codePostal: string;
}
interface citySelectorProps {
    token:string,
    ville: Ville[]
}
const CitySelector: React.FC<citySelectorProps> = ({token,ville}) => {
    const [selectedCities, setSelectedCities] = useState<Ville[]>([]);

    useEffect( () => {
        const updateCity =async() => {console.log('useEffect selectedCities', selectedCities)
        if(selectedCities.length>0){
            const response =await addCityProfileVisiteur(token, {selectedCities});
            if (response.success) {
                /* swal('Youpi ! La ville a été ajouté !', {
                    icon: 'success',
                })*/
            }
        }}
        updateCity();
    }, [selectedCities]) ;

    useEffect(() => {
        setSelectedCities(ville)
        console.log('ville',selectedCities)
    }, [])

    const handleCityClick =  async (selectedCity: {
        place_id: string | null;
        address_components: { long_name: string; short_name: string; types: string[] }[];
    }) => {
        if (!selectedCity || !selectedCity.address_components) {
            console.error('Aucune donnée de ville reçue.');
            return;
        }
        const codePostalObj = selectedCity.address_components.find(a => a.types.includes('postal_code'));
        let code_postal = codePostalObj ? codePostalObj.short_name : '';

        const nom = selectedCity.address_components.find(item => item.types.includes('locality'));
        const nomFinal = nom ? nom.short_name : '';

        if (!code_postal) {
            console.log('tok tk tok ', token)
            const response = await getPostalCodeByCity(nomFinal, token);
            if (!response.success) {
                console.log('Erreur lors de la récupération du code postal');
                return;
            }
            code_postal = response.codePostal;
        }

        const cityNew: Ville = { nom: nomFinal, codePostal: code_postal};

        setSelectedCities(prevCities => {
            if (!prevCities.some(existingCity => existingCity.nom === cityNew.nom && existingCity.codePostal === cityNew.codePostal)) {

                return [...prevCities, cityNew];
            } else {
                swal('Erreur', 'Vous avez déjà ajouté cette ville', 'error');
                return prevCities;
            }

        });


    };

    const removeCity2 = (index: number) => {
        setSelectedCities(prevCities => prevCities.filter((_, i) => i !== index));

    };

    return (
        <section className='p-5 p-2em rounded-xl mb-5 bg-gray-100 shadow-lg' data-testid='city-selector-block'>
            <div className='my-5'>
                <label className='mt-10 font-bold'>Choisir une ou plusieurs villes *</label>
                <p className='text-sm'>Les villes que vous aurez choisies seront les villes où vous pouvez faire des visites.</p>
                <div className='relative mt-3'>
                    <Autocomplete
                        data-testid='creer_profil_visiteur-input-ville_a_visiter'
                        apiKey={process.env.REACT_APP_GOOGLE}
                        key={process.env.REACT_APP_GOOGLE}
                        className='rounded-full outline-none pl-3 w-full p-5 cursor-pointer mt-1 py-2 mb-1'
                        onPlaceSelected={(place) =>
                        {console.log('Place sélectionnée:', place);
                            handleCityClick(place)}}
                        options={{ types: ['geocode'],
                            componentRestrictions: { country: 'fr' } }}
                    />
                </div>
                <div className='mt-5 flex gap-2 flex-wrap'>
                    {selectedCities.map((city, index) => (
                        <p key={index} className='bg-white flex justify-between items-center text-black w-fit font-bold py-2 px-4 rounded-full shadow border p-4'>
                            {city.nom} {city.codePostal}
                            <span
                                data-testid={`city_selector-remove_city-${city.codePostal}_${city.nom}`}
                                className='ml-2 p-1 border rounded-full text-red-500 hover:text-white hover:bg-red-500 cursor-pointer'
                                  onClick={() => removeCity2(index)}>
                <Close />
              </span>
                        </p>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default CitySelector;
