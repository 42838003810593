import 'react-alice-carousel/lib/alice-carousel.css'
import './DateSlider.css'
import prev from '../../assets/svg/arrow-prev.svg'
import next from '../../assets/svg/arrow-next.svg'
import {
  createDisponibilite,
  deleteDisponibilite,
  getDisponibiliteByUser,
} from '../../api/Disponibilite'
import { useEffect, useState } from 'react'
import { listeProchains15Jours } from '../../utils/Data.utils'

interface DateSliderProps {
  token: string
  isEditable: boolean
}

interface DataDisponibilitesProps {
  dataDisponibilites: string
  id: number
}

const DateSlider: React.FC<DateSliderProps> = ({ token, isEditable }: DateSliderProps) => {
  const [currentDataDisponibilites, setCurrentDataDisponibilites] = useState<
    DataDisponibilitesProps[]
  >([])
  const [loadData, setLoadData] = useState(false)
  const dateActuelle = new Date() // Obtenez la date actuelle
  const prochains15Jours = listeProchains15Jours(dateActuelle)
  const [currentDateIndex, setCurrentDateIndex] = useState(0)
  const showDates = prochains15Jours.slice(currentDateIndex, currentDateIndex + 14)

  useEffect(() => {
    if (token) {
      getDisponibiliteByUser(token)
        .then((response: any) => {
          // Récupérez les dates disponibles pour l'utilisateur actuel
          setCurrentDataDisponibilites(response.disponibilites)
          setLoadData(true)
        })
        .catch((error: any) => {
          console.error('Erreur lors de la récupération des disponibilités.', error)
        })
    }
  }, [token]) // Ajoutez token comme dépendance

  const toggleDateActive = (index: number) => () => {
    if (isEditable) {
      const announcedDisponibiliteIndex = currentDataDisponibilites.findIndex(
        (disponibilite) => disponibilite.dataDisponibilites === showDates[index].date,
      )

      if (announcedDisponibiliteIndex === -1) {
        createDisponibilite(token, showDates[index].date)
          .then((e) => {
            setCurrentDataDisponibilites((prev) => [
              ...prev,
              {
                dataDisponibilites: e.disponibilite.dataDisponibilites,
                id: e.disponibilite.id,
              },
            ])
          })
          .catch((error: any) => {
            console.error('Erreur lors de la création de la disponibilité.', error)
          })
      } else {
        deleteDisponibilite(token, currentDataDisponibilites[announcedDisponibiliteIndex].id)
          .then(() => {
            setCurrentDataDisponibilites((prev) => {
              const updatedData = [...prev]
              updatedData.splice(announcedDisponibiliteIndex, 1)
              return updatedData
            })
          })
          .catch((error: any) => {
            console.error('Erreur lors de la suppression de la disponibilité.', error)
          })
      }
    }
  }

  const handlePrevClick = () => {
    setCurrentDateIndex((prevIndex) => Math.max(prevIndex - 1, 0))
  }

  const handleNextClick = () => {
    setCurrentDateIndex((prevIndex) => Math.min(prevIndex + 1, prochains15Jours.length - 5))
  }

  return (
    <>
      {loadData ? (
        <div className='flex justify-center items-center  relative mx-2 '>
          <button
            onClick={handlePrevClick}
            className='text-gray-500 hover:text-gray-700 focus:outline-none absolute -left-10'
          >
            <img src={prev} alt='prev' className='w-[30px]' />
          </button>
          <div className='flex items-center justify-center   gap-2     flex-wrap  '>
            {showDates.map((date, index) => (
              <div
                className={`flex  justify-center flex-grow-0 flex-col items-center gap-1 py-1 px-2 xl:py-4 xl:px-8 flex-1 w-1/2 rounded-md border border-solid border-gray-300 bg-white ${
                  currentDataDisponibilites.some(
                    (disponibilite) => disponibilite.dataDisponibilites === date.date,
                  )
                    ? 'border border-solid border-yellow-400 bg-yellow-100/[.8]'
                    : ''
                } ${isEditable ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                onClick={toggleDateActive(index)}
                key={index}
              >
                <div className='date-day'>{date.day}</div>
                <div className='date-number'>{date.number}</div>
                <div className='date-month'>{date.month}</div>
              </div>
            ))}
          </div>
          <button
            onClick={handleNextClick}
            className='text-gray-500 hover:text-gray-700 focus:outline-none absolute -right-10'
          >
            <img src={next} alt='next' className='w-[30px]' />
          </button>
        </div>
      ) : (
        <p>loading ...</p>
      )}
    </>
  )
}

export default DateSlider
