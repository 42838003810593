import Axios from 'axios';

const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN
export const faireReclamationAsVisiteur = async (token: string, payment_intent_id: string) => {
    try {

        const response = await Axios.post(`${SERVER_APP_DOMAIN}api/reclamation/refuser-compte-rendu`,
            {payment_intent_id},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
        return response.data
    } catch (error) {
        console.error('Erreur lors de la requête :', error)
        throw error
    }
}