import React, {useEffect, useState} from 'react';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

import NosConseilsDialog from '../InscriptionVisiteur/PreferencesVisiteur/NosConseilsDialog/NosConseilsDialog'
import {modifyTarif} from '../../api/ProfileVisiteur/ModifyTarif';

interface price {
    tarif: number;
}
interface priceModificatorProps {
    token: string;
    price: price;
}

const PriceModificatorComponent:React.FC<priceModificatorProps>  = ({ token, price }) => {

    const [formValues, setFormValues] = useState<price>(price)
    const [isPriceModified, setIsPriceModified] = useState<boolean>(false)
    const [validationErrors, setValidationErrors] = useState<Record<string, string>>({})
    const [openDialog, setOpenDialog] = useState(false)

    useEffect(() => {
        setFormValues(price)
        console.log('price',price)
    }, [])
    const handleDialogOpen = () => {
        setOpenDialog(true)
    }
    const handleDialogClose = () => {
        setOpenDialog(false)
    }
    const handleAmountChange = (e: { target: { value: any } }) => {
        const inputValue = Number(e.target.value)
        if (inputValue >= 0) {
            console.log(inputValue)
            setFormValues((prevFormValues) => ({
                ...prevFormValues,
                tarif: inputValue,
            }))
            setIsPriceModified(true);
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            console.log('useEffect formValues', formValues)
            if(isPriceModified){
                modifyTarif(token, {formValues})
            }
        }, 500) // Attendre 500ms après la dernière frappe

        return () => clearTimeout(timer) // Nettoyer le timer à chaque frappe


    }, [isPriceModified,formValues]) ;


    return (
        <div className='p-5 p-2em rounded-xl mb-5 bg-gray-100 shadow-lg mt-5'>
            <h3 className='text-18px mt-20px'>Vos tarifs</h3>
            {validationErrors.tarif && <p className='text-red-500'>{validationErrors.tarif}</p>}
            <div className='flex mt-5 items-center space-x-2'>
                <div className='flex items-center'>
                    <div className='cursor-pointer text-red-500' onClick={handleDialogOpen}>
                        <ErrorOutline />
                    </div>
                </div>
                <p>Nos conseils</p>
            </div>

            <NosConseilsDialog open={openDialog} onClose={handleDialogClose} />
            <span className='text-0.8em italic block mb-1em'>
        Ce que vous souhaitez que les mandants paient par service
      </span>
            <input
                data-testid='preferences_visiteur-boutton-tarif'
                className='rounded-full outline-none pl-3 w-full p-10px mt-1 py-2 px-5 mb-1'
                type='number'
                placeholder={String(formValues.tarif)}
                onChange={handleAmountChange}
            />
            <p className='mt-5'>
                Ce que vous gagnerez par service:{' '}
                <b data-testid='preferences_visiteur-value-tarif'>{(formValues.tarif * 0.8 > 1 ? formValues.tarif * 0.8 : 0).toFixed(2)} €</b>
            </p>
        </div>
    );
};

export default PriceModificatorComponent;
