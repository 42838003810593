import React, { useState, useEffect } from 'react';
import {getAvisByUserNew} from '../../api/Avis/GetAvisByUserNew';
import './index.css'
import './index.mobile.css' // Assurez-vous que le chemin du fichier CSS est correct.
interface ListAvisProps {
    token: string;
    user: string;
}
interface Avis {
    id: string;
    note: string;
    commentaire: string;
}

const ListeAvis: React.FC<ListAvisProps> = ({token,user}) => {

    const [avis, setAvis] = useState<Avis[]>([]);
    const [isOpen, setIsOpenState] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);  // Etat de chargement
    const [error, setError] = useState<string | null>(null);   // Etat d'erreur

    const setIsOpen = ( value: boolean,e: any) => {
        e.stopPropagation();
        setIsOpenState(value);
    };
    useEffect(() => {
        // Fonction pour récupérer les avis
        const getListAvis = async () => {
            setLoading(true); // Début de chargement
            setError(null);   // Réinitialiser l'erreur

            try {
                const response = await getAvisByUserNew(token, user);
                if (response.success) {
                    setAvis(response.avis);
                } else {
                    setError('Impossible de récupérer les avis');
                }
            } catch (err) {
                setError('Une erreur est survenue lors de la récupération des avis');
            } finally {
                setLoading(false); // Fin du chargement
            }
        };

        getListAvis();
    }, [token, user]);  // Réexécute l'effet si `token` ou `user` changent
    return (
        <div className="text-center">
        {isOpen && (
        <div className="modal-overlay"  data-testid='liste_avis-popup'>
            <div className="modal-content" >
                {/* Titre */}
                <h2 className="text-xl font-semibold text-gray-800">Derniers Avis</h2>
                {/* Afficher le chargement */}
                {loading && <p>Chargement...</p>}

                {/* Afficher l'erreur */}
                {error && <p className="text-red-500">{error}</p>}
                {/* Liste des avis */}
                <ul className="space-y-4 mt-4">
                    {avis.length > 0 ? (
                        avis.map((a) => (
                            <li key={a.id} data-testid={`select-user-${a.id}`} className="p-4 border border-gray-200 rounded-lg shadow-sm bg-gray-50">
                                <p className="text-lg font-semibold text-yellow-500">⭐ {a.note}/5</p>
                                <p className="text-gray-700 mt-1">{a.commentaire}</p>
                            </li>
                        ))
                    ) : (
                        <p className="text-gray-600 text-center">Aucun avis disponible.</p>
                    )}
                </ul>

                {/* Bouton de fermeture */}
                <button data-testid='liste_avis-popup-close_button'
                    onClick={(e) => setIsOpen(false,e)}
                    className="mt-4 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition"
                >
                    Fermer
                </button>
            </div>
        </div>
        )}
            {/* Bouton pour rouvrir la pop-up */}
            {!isOpen && (
                <button  data-testid='liste_avis-popup-open_button'
                    onClick={(e) => setIsOpen(true,e)}  // Réouvre la pop-up
                    className="mt-4 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition"
                >
                    Voir les avis
                </button>
            )}
        </div>

    );
};

export default ListeAvis;
