import Axios from 'axios'

const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN;



export const createProfileVisiteur = async (token: string, profileData: object) => {
    try {
        const response = await Axios.post(`${SERVER_APP_DOMAIN}api/profileVisiteur/`, profileData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })

        return response.data
    } catch (error) {
        console.error('Erreur lors de la requête :', error)
        throw error
    }
}
