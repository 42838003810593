import React, { useEffect, useState } from 'react'
import './Navigation.css'
import { authenticate } from '../../api/Authentification'
import { ProfilType } from './Profil.type'

interface Props {
  components: React.ReactNode[]
  handleFormSubmit: () => void
  setMissingValue: React.Dispatch<React.SetStateAction<boolean>>
  missingValue: boolean
  validation: [
    formDataValidPart1: {
      date: boolean
      heureVisite: boolean
    },
    formDataValidPart2: {
      proprietaire: boolean
      adresseBien: boolean
      ville: boolean
      codePostal: boolean
      typeBien: boolean
    },
    formDataValidPart3: {
      selectedProfile: boolean
    },
  ]
  authProps: {
    token: string
    isLoggedIn: boolean
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
  }

  formData: {
    date: Date
    heureVisite: string
    proprietaire: string
    adresseBien: string
    ville: string
    codePostal: string
    typeBien: string
    prix: { email: string; prix: number }[]
    informationsComplementaires: string
    selectedProfiles: ProfilType[]
  }
  setFormData: React.Dispatch<
    React.SetStateAction<{
      date: Date
      heureVisite: string
      proprietaire: string
      adresseBien: string
      ville: string
      prix: { email: string; prix: number }[]
      informationsComplementaires: string
      codePostal: string
      typeBien: string
      selectedProfiles: ProfilType[]
    }>
  >
}

const Navigation: React.FC<Props> = ({
  authProps,
  formData,
  components,
  setFormData,
  handleFormSubmit,
  validation,
  setMissingValue,
  missingValue,
}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [basketExist, setBasketExist] = useState(false)
  const totalSteps = components.length

  // Tableau d'étapes, chaque élément est un objet avec des propriétés 'active' et 'done'
  const [steps, setSteps] = useState<Array<{ active: boolean; done: boolean }>>(
    Array(totalSteps).fill({ active: false, done: false }),
  )
  useEffect(() => {
    // Logique pour mettre à jour formData si basketToFinish existe
    const dataBasket = localStorage.getItem('basketToFinish')
    const basketToFinish = dataBasket ? JSON.parse(dataBasket) : ''

    if (basketToFinish) {
      setBasketExist(true)
      setFormData(basketToFinish)

      setCurrentStep(3)
    }
  }, [])

  const stepNames = [
    'Planifier votre visite',
    'Ajouter des informations',
    'Choisir un visiteur',
    'Envoyer la demande',
  ]

  const markStepAsDone = (stepIndex: number) => {
    const updatedSteps = steps.map((step, index) => ({
      active: index === stepIndex,
      done: index < stepIndex,
    }))
    setSteps(updatedSteps)
  }

  useEffect(() => {
    markStepAsDone(currentStep)
  }, [currentStep])

  const nextStep = () => {

    const valeurs = Object.values(validation[currentStep])
    const value = valeurs.every((valeur) => valeur)
    console.log('value',currentStep,value)
    if (!value) {
      setMissingValue(true)
    } else {
      setMissingValue(false)
      if (currentStep < totalSteps - 1) {
        setCurrentStep(currentStep + 1)
      }
    }
  }

  const prevStep = () => {
    if (basketExist) localStorage.removeItem('basketToFinish')

    setMissingValue(false)
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }
  const isLastStep = currentStep === totalSteps - 1

  // Fonction pour calculer les classes CSS en fonction de l'état de l'étape
  const getStepClasses = (stepIndex: number) => {
    const step = steps[stepIndex]
    const classes = ['dots-steps']

    if (step.active) {
      classes.push('dots-steps-active')
    } else if (step.done) {
      classes.push('dots-steps-done')
    }

    return classes.join(' ')
  }

  return (
    <div className=''>
      <ul className='steps'>
        {components.map((_, index) => {
          return (
            <li key={index}>
              <span className={getStepClasses(index)}>{index + 1}</span>
              {stepNames[index]}
            </li>
          )
        })}
      </ul>

      <div className=''>{React.cloneElement(components[currentStep] as React.ReactElement)}</div>
      {missingValue && (
        <div className='programmation-visite-error-message'>
          Merci de completer l&apos;ensemble des champs{' '}
        </div>
      )}
      <div className='btn-navigation'>
        {currentStep > 0 && (
          <button className='bg-tranparent' onClick={prevStep}>
            {basketExist ? 'Annuler' : 'Précédent'}
          </button>
        )}
        {authProps.token ? (
          <button className='bg-orange' onClick={isLastStep ? handleFormSubmit : nextStep}>
            {isLastStep ? 'Terminer' : 'Suivant'}
          </button>
        ) : (
          <>
            {isLastStep ? (
              <a href='/inscription'>
                <button
                  onClick={() => localStorage.setItem('basketToFinish', JSON.stringify(formData))}
                  className=' bg-orange'
                >
                  Connexion pour valider
                </button>
              </a>
            ) : (
              <button className='bg-orange' onClick={nextStep}>
                Suivant
              </button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Navigation
