import Footer from '../Footer/Footer'
import '../../App.css'
import './index.css'
import './index.tablette.css'
import './index.mobile.css'
import { StarRate } from '@mui/icons-material'
import etourneau from '../../assets/images/image_oiseaux/etourneau.png'

import { SetStateAction, useEffect, useState } from 'react'
import DateSlider from '../Swiper/DateSlider'
import Menu from '../Menu/Menu'
import { calculateTimeAgo, convertDate } from '../../utils/Data.utils'
import { updateProfileInfo } from '../../api/Profile'
import withAuth from '../Security/withAuth'
import { ProfilType } from '../ProgrammationVisite/Profil.type'
import { getAvisByUser } from '../../api/Avis/GetAvisByUser'
import { updateProfileVisiteur } from '../../api/ProfileVisiteur/UpdateProfileVisiteur'
import { getProfileVisiteur } from '../../api/ProfileVisiteur/GetProfileVisiteur'

interface ProfilVisiteurProps {
  authState: {
    email: string
    connectedAccountID: string
    firstname: string
    lastname: string
    phoneNumber: string
    createdAt: Date
    updatedAt: Date
    userImg: string
    birthdate: string
    city: string
    isVisiteur: boolean
    isVerifiedStripeAccount: boolean
  }
  profil: ProfilType
  authProps: {
    token: string
    isLoggedIn: boolean
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
  }
}

type AvisType = {
  username: string
  stars: number
  profileImage: string
  date: Date
  comment: string
}

type DataVisiteurType = {
  disponibleImmediatement: boolean
  nbAvis: number
  note: number
  parlezDeVous: string
}

const ProfilVisiteur: React.FC<ProfilVisiteurProps> = ({
  profil,
  authState,
  authProps,
}: ProfilVisiteurProps) => {
  const [selectedOption, setSelectedOption] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)
  const [isHovered, setIsHovered] = useState(false)
  const [dataVisiteur, setDataVisiteur] = useState<DataVisiteurType>({
    disponibleImmediatement: false,
    nbAvis: 0,
    note: 0,
    parlezDeVous: 'Ecrire une petite description',
  })
  const [avisData, setAvisData] = useState<AvisType[]>([])
  const [curentProfil, setCurentProfil] = useState({
    userImg: profil.userImg,
    description: profil.description,
  })

  const { token } = authProps

  useEffect(() => {
    setCurentProfil({
      userImg: profil.userImg,
      description: profil.description,
    })
  }, [profil])

  useEffect(() => {
    try {
      if (token) {
        getAvisByUser(token).then((res) => {
          if (Array.isArray(res.avis)) {
            const newAvisData = res.avis.map((obj: any) => ({
              username: obj.donneur.firstname + ' ' + obj.donneur.lastname,
              stars: obj.note,
              profileImage: obj.donneur.userImg,
              date: obj.updatedAt,
              comment: obj.commentaire,
            }))
            setAvisData([...avisData, ...newAvisData])
          }
        })
        getProfileVisiteur(token).then((res) => {
          setDataVisiteur({
            disponibleImmediatement: res.data.profileVisiteur.disponibleImmediatement,
            nbAvis: res.data.profileVisiteur.nbAvis,
            note: res.data.profileVisiteur.note,
            parlezDeVous: res.data.profileVisiteur.parlez_de_vous,
          })
        })
      }
    } catch (error) {
      console.error('Erreur lors de la création du profil visiteur', error)
    }
  }, [token])
  const handleImageClick = () => {
    // Déclenchez le clic de l'input file lorsque l'image est cliquée
    document?.getElementById('fileInput')?.click()
  }

  const handleParlezDeVousChange = async (value: string) => {
    setDataVisiteur((prevDataVisiteur) => ({
      ...prevDataVisiteur,
      parlezDeVous: value,
    }))
    const data = {
      parlezDeVous: value,
    }
    await updateProfileVisiteur(token, data)
  }

  const handleFileChange = async (event: any) => {
    const formData = new FormData()
    formData.append('userImg', event.target.files[0])
    formData.append('description', profil.description)

    try {
      const response = await updateProfileInfo(token, formData)
      // Mettre à jour l'état local avec les nouvelles informations
      setCurentProfil({
        userImg: response.profile.userImg,
        description: response.profile.description,
      })
    } catch (error: any) {
      console.log(error)
    }
  }

  const sortComments = (option: string) => {
    if (option === 'mieux_note') {
      return avisData.slice().sort((a, b) => (b.stars as number) - (a.stars as number))
    }

    if (option === 'moins_recent') {
      return avisData.slice().sort((a, b) => (new Date(a.date) as any) - (new Date(b.date) as any))
    }

    if (option === 'plus_recent') {
      // Notez que j'ai supprimé l'espace après 'plus_recent'
      return avisData.slice().sort((a, b) => (new Date(b.date) as any) - (new Date(a.date) as any))
    }

    // Si l'option n'est pas reconnue, retournez le tableau non trié par défaut
    return avisData.slice()
  }

  const sortedComments = sortComments(selectedOption)

  const renderStars = (stars: number) => {
    const starElements = []

    for (let i = 0; i < stars; i++) {
      starElements.push(
        <svg
          key={i}
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='#F79009'
        >
          <path d='M18.3203 8.93603L14.7969 12.011L15.8524 16.5892C15.9082 16.8285 15.8923 17.079 15.8065 17.3093C15.7208 17.5396 15.5691 17.7396 15.3703 17.8841C15.1716 18.0287 14.9346 18.1114 14.6891 18.1221C14.4436 18.1327 14.2004 18.0707 13.9899 17.9438L9.99689 15.522L6.01252 17.9438C5.80202 18.0707 5.55881 18.1327 5.31328 18.1221C5.06775 18.1114 4.83079 18.0287 4.63204 17.8841C4.4333 17.7396 4.28157 17.5396 4.19584 17.3093C4.1101 17.079 4.09416 16.8285 4.15002 16.5892L5.20392 12.0157L1.67970 8.93603C1.49331 8.77526 1.35852 8.56304 1.29225 8.32598C1.22598 8.08892 1.23117 7.83757 1.30718 7.60345C1.38319 7.36933 1.52663 7.16287 1.71952 7.00994C1.91240 6.85702 2.14614 6.76445 2.39142 6.74384L7.03674 6.34149L8.85002 2.01649C8.94471 1.78955 9.10443 1.59570 9.30907 1.45935C9.51371 1.32300 9.75411 1.25024 10 1.25024C10.2459 1.25024 10.4863 1.32300 10.691 1.45935C10.8956 1.59570 11.0553 1.78955 11.15 2.01649L12.9688 6.34149L17.6125 6.74384C17.8578 6.76445 18.0915 6.85702 18.2844 7.00994C18.4773 7.16287 18.6207 7.36933 18.6968 7.60345C18.7728 7.83757 18.778 8.08892 18.7117 8.32598C18.6454 8.56304 18.5106 8.77526 18.3242 8.93603H18.3203Z' />
        </svg>,
      )
    }

    return starElements
  }

  return (
    <div>
      <Menu authState={authState} authProps={authProps} />
      <div className='profil-visiteur'>
        <div className='infos-user'>
          <div className='flex flex-col items-center justify-center w-80 p-10 bg-white border border-solid border-neutral-300 rounded-md border-none shadow-md'>
            <div className='userCircle'>
              <div
                className={`userCircle-img ${isHovered ? 'hovered' : ''}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => handleImageClick()}
              >
                <img
                  src={curentProfil.userImg ? curentProfil.userImg : etourneau}
                  alt="profil's photo"
                />
                <input
                  type='file'
                  id='fileInput'
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                {isHovered && <span>Modifier la photo</span>}
              </div>

              <h2 className='text-2xl mb-4'>
                {authState.firstname}
              </h2>

              {profil.profileVisiteur.note && profil.profileVisiteur.nbAvis ? (
                <div className='notes-avis'>
                  <StarRate />
                  <p>{profil.profileVisiteur.note} &nbsp;</p>
                  <p>({profil.profileVisiteur.nbAvis} avis)</p>
                </div>
              ) : (
                ''
              )}
            </div>

            <textarea
              className='resize-none border rounded-md border-none p-3 w-full focus:outline-none focus:ring focus:border-blue-300'
              placeholder='Parlez nous de vous :) Cela apparaîtra sur votre profil et permettra aux mandant de mieux vous connaître. Vous pouvez par exemple parler de vos potentielles expériences dans le domaine immobilier, de votre ponctualité…'
              value={dataVisiteur.parlezDeVous}
              onChange={async (e: { target: { value: string } }) => {
                await handleParlezDeVousChange(e.target.value)
              }}
              rows={4}
            />
            <div className='mt-6 text-center'>
              {dataVisiteur.disponibleImmediatement && (
                <p className='text-lg font-bold mb-2'>Disponible immédiatement</p>
              )}
              <hr className='border-t-2 border-gray-300 my-2' />
              <p className='text-sm text-gray-500'>
                Membre depuis le {convertDate(authState.createdAt)}
              </p>
            </div>
          </div>
        </div>
        <div className='profil-visiteur-partDroite'>
          <h2 className='mb-5'>Calendrier de disponibilité</h2>
          <DateSlider token={token} isEditable={false} />
          <div className='commentaires-utilisateurs-container'>
            <div className='commentaires-utilisateurs-header'>
              <h2>Commentaires</h2>
              <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                <option value='plus_recent'>Plus récent</option>
                <option value='mieux_note'>Mieux noté</option>
                <option value='moins-recent'>Moins récent</option>
              </select>
            </div>
            <div className='liste-commentaires-utilisateurs'>
              {sortedComments.length > 0 ? (
                sortedComments.map((comment, index) => (
                  <div key={index} className='commentaire-utilisateur'>
                    <div className='commentaire-utilisateur-container'>
                      <div className='commentaire-utilisateur-info'>
                        <img src={comment.profileImage ?? etourneau} alt={comment.username} />
                        <div>
                          <h3>{comment.username}</h3>
                          <p>{calculateTimeAgo(comment.date)}</p>
                          <div className='commentaire-utilisateur-info-stars'>
                            {renderStars(comment.stars)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>{comment.comment}</p>
                  </div>
                ))
              ) : (
                <p>Vous n&apos;avez pas de commentaires</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default withAuth(ProfilVisiteur)
