import withAuth from '../Security/withAuth'
import React, { useState } from 'react'
import { createAvis } from '../../api/Avis/CreateAvis';
import swal from 'sweetalert';

interface AvisModalProps {
    donneurId: string;
    receveurId: string;
    visiteId: string;
    token: string;
}

const CreateAvisModal: React.FC<AvisModalProps> = ({ donneurId, receveurId, visiteId, token }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isAlreadySubmit, setIsAlreadySubmit] = useState(false);
    const [note, setNote] = useState<number>(5);
    const [commentaire, setCommentaire] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async () => {
        if (!commentaire.trim()) {
            setError('Le commentaire ne peut pas être vide.');
            return;
        }
        setLoading(true);
        setError(null);

        try {
            const response =   await createAvis(token, { donneurId, receveurId, note, commentaire, visiteId });
            setIsOpen(false);
            setCommentaire('');
            setNote(5);
           if(response.isAvisAlreadyExist){
               setIsAlreadySubmit(true);
               await swal('Aie!', 'Vous avez deja laissé un message', 'warning');
            }if(response.avis){
                setIsAlreadySubmit(true);
                await swal('Good job!', 'Merci d\'avoir laissé un avis', 'success');
            }
        } catch (err) {
            await swal('Aie!', 'Une erreur est survenue', 'error');
            setError('Une erreur est survenue lors de l\'envoi de l\'avis.');
        } finally {

            setLoading(false);
        }
    };

    return (
        <div className='flex items-center justify-center w-full'>
            {!isAlreadySubmit && (
            <button
                data-testid='avis-button-laisser_un_avis'
                onClick={() => setIsOpen(true)}
                    className='btn btn-blue bg-blue-500 w-fit'>
                Laisser un avis
            </button>
            )}
            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96 text-gray-900">
                        <h2 className="text-lg font-bold mb-4">Laisser un avis</h2>

                        {error && <p className="text-red-500">{error}</p>}

                        <label className="block mb-2 text-gray-700">Note :</label>
                        <select
                            data-testid="avis_modal-select-note"
                            value={note}
                            onChange={(e) => setNote(Number(e.target.value))}
                            className="w-full p-2 border rounded mb-4 text-black"
                        >
                            {[ 5,4,3,2,1].map((n) => (
                                <option key={n} value={n}>{n}</option>
                            ))}
                        </select>

                        <label className="block mb-2 text-gray-700">Commentaire :</label>
                        <textarea
                            data-testid="avis_modal-textarea-commentaire"
                            value={commentaire}
                            onChange={(e) => setCommentaire(e.target.value)}
                            className="w-full p-2 border rounded mb-4 text-black"
                            rows={3}
                        />

                        <div className="flex justify-end gap-2">
                            <button
                                data-testid="avis_modal-button-annuler"
                                onClick={() => setIsOpen(false)}
                                className="px-4 py-2 bg-gray-300 text-gray-900 rounded"
                                disabled={loading}
                            >
                                Annuler
                            </button>
                            <button
                                data-testid="avis_modal-button-valider"
                                onClick={handleSubmit}
                                className="px-4 py-2 bg-green-500 text-white rounded"
                                disabled={loading}
                            >
                                {loading ? 'Envoi...' : 'Valider'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreateAvisModal;
