import Axios from 'axios';
const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN;
export const getAvisByVisiteId = async (token: string,visiteId: string) => {
    try {
        const response = await Axios.get(`${SERVER_APP_DOMAIN}api/avis/visiteId/${visiteId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })

        return response.data
    } catch (error) {
        console.error('Erreur lors de la requête :', error)
        throw error
    }
}