import { BrowserRouter, Routes, Route } from 'react-router-dom'
import HomePage from './components/Home/HomePage'
import { useEffect, useState } from 'react'
import PageNotFound from './components/PageNotFound/PageNotFound'
import Inscription from './components/Authentification/Inscription/Inscription'
import ProfilVisiteur from './components/ProfilVisiteur/ProfilVisiteur'
import ProgrammationVisite from './components/ProgrammationVisite/ProgrammationVisite'
import Dashboard from './components/Dashboard/Dashboard'
import Connexion from './components/Authentification/Connexion/Connexion'
import ValidationCompte from './components/Authentification/ValidationCompte/ValidationCompte'
import MotDePasseOublie from './components/Authentification/MotDePasseOublie/MotDePasseOublie'
import AuthRedirect from './components/Authentification/AuthRedirect/AuthRedirect'
import { getInfoUser } from './api/User'
import { getProfileInfo } from './api/Profile'
import InscriptionVisiteur from './components/InscriptionVisiteur/InscriptionVisiteur'
import CompteRendu from './components/CompteRendu/CompteRendu'
import ServerDown from './components/ServerDown/ServerDown'
import Paiement from './components/Paiement/Paiement'
import AboutPage from './components/AboutPage/AboutPage'
import CookiePolicyPage from './components/CookiePolicyPage/CookiePolicyPage'
import PrivacyPolicyPage from './components/PrivacyPolicyPage/PrivacyPolicyPage'
import DAC7Info from './components/DAC7/DAC7Info'
import DAC7Questions from './components/DAC7/DAC7Questions'
import HowItWorksPage from './components/HowItWorksPage/HowItWorksPage'
import GeneralConditionsPage from './components/GeneralConditions/GeneralConditions'

const App: React.FC = () => {
  const [token, setToken] = useState('')

  const [authState, setAuthState] = useState({
    firstname: '',
    lastname: '',
    userImg: '',
    email: '',
    phoneNumber: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    birthdate: '',
    city: '',
    codePostal: '',
    cityOfBirth: '',
    isVisiteur: false,
    connectedAccountID: '',
    isVerifiedStripeAccount: false,
    identityCardStatus: null,
  })

  const [profil, setProfil] = useState({
    id: '',
    userImg: '',
    description: '',
    visiteur: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    email: '',
    isProfileVisiteur: false,
    profileVisiteur: {
      id:'',
      profileId: '',
      nom: '',
      prenom: '',
      nbAvis: '',
      civilite: '',
      dateDeNaissance: new Date(),
      villeDeNaissance: '',
      adressePostale: '',
      codePostal: '',
      telephone: '',
      email: '',
      parlezDeVous: '',
      disponibleImmediatement: false,
      createdAt: new Date(),
      updatedAt: new Date(),
      tarif: 0,
      note: 0,
      delaiAcceptation: '',
      preferenceModification: '',
      preferenceAnnulation: '',
      villeVisiteur:{}
    },
  })

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem('token')?.replace(/^"|"$/g, '')
    if (token) {
      setToken(token)
      setIsLoggedIn(true)
      getInfoUser(token)
        .then((response) => {
          setAuthState({
            ...authState,
            firstname: response.user.firstname,
            lastname: response.user.lastname,
            userImg: response.user.userImg,
            email: response.user.email,
            phoneNumber: response.user.phoneNumber,
            createdAt: response.user.createdAt,
            updatedAt: response.user.updatedAt,
            birthdate: response.user.birthdate,
            city: response.user.city,
            cityOfBirth: response.user.cityOfBirth,
            codePostal: response.user.codePostal,
            isVisiteur: response.isVisiteur,
            connectedAccountID: response.connectedAccountID,
            isVerifiedStripeAccount: response.isVerifiedStripeAccount,
            identityCardStatus: response.identityCardStatus,
          })
        })
        .catch(() => {
          localStorage.removeItem('token')
          window.location.reload()
          window.location.href = '/inscription'
        })

      getProfileInfo(token)
        .then((response) => {
          setProfil({
            ...profil,
            userImg: response.profile.userImg,
            description: response.profile.description,
          })
        })
        .catch((error: any) => {
          console.log(error)
        })
    }
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route
          index
          element={
            <HomePage authProps={{ token, isLoggedIn, setIsLoggedIn }} authState={authState} />
          }
        />
        <Route
          path='profil-visiteur'
          element={
            <ProfilVisiteur
              authProps={{ token, isLoggedIn, setIsLoggedIn }}
              profil={profil}
              authState={authState}
            />
          }
        />
        <Route
          path='programmation-visite'
          element={
            <ProgrammationVisite
              authState={authState}
              authProps={{ token, isLoggedIn, setIsLoggedIn }}
            />
          }
        />
        <Route
          path='/compte-rendu/:id'
          element={
            <CompteRendu authState={authState} authProps={{ token, isLoggedIn, setIsLoggedIn }} />
          }
        />
        <Route path='server-down' element={<ServerDown />} />
        <Route
          path='dashboard'
          element={
            <Dashboard authProps={{ token, isLoggedIn, setIsLoggedIn }} authState={authState} />
          }
        />
        <Route path='inscription' element={<Inscription />} />
        <Route
          path='paiement'
          element={<Paiement authProps={{ token, isLoggedIn, setIsLoggedIn }} />}
        />
        <Route
          path='inscription-visiteur'
          element={
            <InscriptionVisiteur
              authState={authState}
              authProps={{ token, isLoggedIn, setIsLoggedIn,setAuthState }}
            />
          }
        />
        <Route path='validation-compte' element={<ValidationCompte />} />
        <Route path='connexion' element={<Connexion />} />
        <Route path='mot-de-passe-oublie' element={<MotDePasseOublie />} />
        <Route path='/authRedirect' element={<AuthRedirect />} />
        <Route path='/a-propos-de-nous' element={<AboutPage />} />
        <Route path='/conditions-générales' element={<GeneralConditionsPage />} />
        <Route path='/politique-utilisation-cookies' element={<CookiePolicyPage />} />
        <Route path='/politique-confidentialite' element={<PrivacyPolicyPage />} />
        <Route path='/DAC7' element={<DAC7Info />} />
        <Route path='/faq-dac7' element={<DAC7Questions />} />
        <Route path='/fonctionnement-voyo' element={<HowItWorksPage />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
