import { useEffect } from 'react'
import { getMessagesByChat } from '../../../api/Messagerie'

interface OtherUser {
  firstname: string
  lastname: string
  city: string
  userImg: string
  email: string
}
type Message = {
  chatId: string
  content: string
  createdAt: Date
  id: string
  updatedAt: Date
  userEmail: string
}

interface ChatBodyProps {
  messages: Message[]
  typingStatus: string
  lastMessageRef: any
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>
  chatId: string | undefined
  token: string
  otherUser: OtherUser | undefined
}

const ChatBody = ({
  messages,
  lastMessageRef,
  typingStatus,
  setMessages,
  chatId,
  token,
  otherUser,
}: ChatBodyProps) => {
  const localStorageData = localStorage.getItem('userData')
  const email = localStorageData !== null && JSON.parse(localStorageData).email

  useEffect(() => {
    const fetchMessages = async () => {
      if (chatId && token) {
        try {
          const response = await getMessagesByChat(token, chatId)
          // Triez les messages par date de création du plus ancien au plus récent
          const sortedMessages = response.messages.sort((a: Message, b: Message) => {
            const dateA = new Date(a.createdAt)
            const dateB = new Date(b.createdAt)

            return dateA.getTime() - dateB.getTime()
          })
          setMessages(sortedMessages)
        } catch (error) {
          console.error('Erreur lors de la récupération des messages :', error)
        }
      }
    }

    fetchMessages()
  }, [])

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [messages])

  return (
    <div className='message__container max-h-48 overflow-y-auto'>
      {messages.map((message: Message) =>
        message.userEmail === email ? (
          <div className='message__chats' key={message.id}>
            <p className='sender__name'>Moi</p>
            <div className='message__sender'>
              <p className=' '>{message.content}</p>
            </div>
          </div>
        ) : (
          <div className='message__chats' key={message.id}>
            <p>{otherUser?.firstname} {otherUser?.lastname}</p>
            <div className='message__recipient '>
              <p>{message.content}</p>
            </div>
          </div>
        ),
      )}

      <div className='message__status'>
        <p>{typingStatus}</p>
      </div>
      <div ref={lastMessageRef}></div>
    </div>
  )
}

export default ChatBody
