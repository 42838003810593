import React, { useEffect, useState, useRef, SetStateAction } from 'react'
import ChatBody from './ChatBody'
import ChatFooter from './ChatFooter'
import './chat.css'
import etourneau from '../../../assets/images/image_oiseaux/etourneau.png'
import { io } from 'socket.io-client'
import { getNotifications, setNotificationWatched } from '../../../api/Notifications'

type Chat = {
  createdAt: string
  email: string
  id: string
  otherUser: {
    firstname: string
    lastname: string
    city: string
    userImg: string
    email: string
  }
  status: number
  updatedAt: string
  userImg: string
}

type Message = {
  chatId: string
  content: string
  createdAt: Date
  id: string
  updatedAt: Date
  userEmail: string
}

type UserOnline = {
  socketID: string
  userId: string
}

interface ChatPageProps {
  authState: {
    email: string
    firstname: string
    lastname: string
    phoneNumber: string
    createdAt: Date
    updatedAt: Date
    userImg: string
    birthdate: string
    city: string
    isVisiteur: boolean
  }

  chat: Chat | undefined
  token: string
}

const ChatPage = ({ chat, authState, token }: ChatPageProps) => {
  const [messages, setMessages] = useState<Message[]>([])
  const [typingStatus, setTypingStatus] = useState('')
  const lastMessageRef = useRef(null)
  const [isUsersOnline, setIsUsersOnline] = useState(false)

  const serverAppDomain = process.env.REACT_APP_WEBSOCKET_URL
  const socket = io(serverAppDomain as string, { transports: ['websocket'] })

  const getNotif = async () => {
    // get all notif from this chat and set all watched
    const res = await getNotifications(token, authState.email)
    if (chat) {
      const filteredNotifs = res.notifications.filter((notif: any) => {
        return notif.content.chatId === chat.id && !notif.isWatched
      })

      for (const notif of filteredNotifs) {
        await setNotificationWatched(token, notif.notifId)
      }
    }
  }

  useEffect(() => {
    socket.on('messageResponse', (data: any) => {
      setMessages([...messages, data])
    })
  }, [socket, messages])

  useEffect(() => {
    getNotif()
  }, [])
  useEffect(() => {
    socket.on('onlineUsersUpdate', (data: UserOnline[]) => {
      setIsUsersOnline(data.some((user) => user.userId === chat?.otherUser.email))
    })
  }, [isUsersOnline])

  return (
    <div className='chat'>
      <div className='message__header'>
        <div className='flex items-center justify-center gap-4'>
          <div className='message__header-img-profil'>
            {isUsersOnline && <div className='round-user-Login'></div>}
            <img src={chat?.otherUser.userImg ? chat?.otherUser.userImg : etourneau} alt='man' />
          </div>
          <div className='flex gap-1 w-fil'>
            <p className='text-base font-medium first-letter:uppercase'>
              {chat?.otherUser?.firstname}
            </p>
            <p className='text-base text-gray-500'>{chat?.otherUser?.firstname?.charAt(0)}</p>
          </div>
        </div>
      </div>

      <ChatBody
        messages={messages}
        typingStatus={typingStatus}
        lastMessageRef={lastMessageRef}
        setMessages={setMessages}
        chatId={chat?.id}
        token={token}
        otherUser={chat?.otherUser}
      />
      <ChatFooter
        chatId={chat?.id}
        socket={socket}
        messages={messages}
        setMessages={setMessages}
        token={token}
        authState={authState}
        otherUserEmail={chat?.otherUser.email}
      />
    </div>
  )
}

export default ChatPage
