import Axios from 'axios';

const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN;

export const getPostalCodeByCity = async (cityName: string, token: string) => {
    try {
        const response = await Axios.get(`${SERVER_APP_DOMAIN}api/villes/code-postal`, {
            params: { city: cityName },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log(response)
        return response.data; // Doit contenir { codePostal: "XXXXX" }
    } catch (error) {
        console.error('❌ [getPostalCodeByCity] Erreur lors de la récupération du code postal :', error);
        throw error;
    }
};
