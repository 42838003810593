import React from 'react';

interface AvisAvecCommentaireEtNotes {
    commentaire: string;
    note: number;
}

const AvisAvecCommentaireEtNotes: React.FC<AvisAvecCommentaireEtNotes> =  ({ commentaire, note  }) => {
    const maxEtoiles = 5;

    return (
        <div className="mt-4 p-4 border rounded-lg shadow-md" data-testid='bloc-avis-avec-commentaire'>
            <p className="text-gray-300  text-xs p-1 rounded-sm cursor-pointer w-1/2" data-testid='commentaire-avis-avec-commentaire'>{commentaire}</p>
            <div className="flex mt-2">
                {[...Array(maxEtoiles)].map((_, index) => (
                    <svg
                        key={index}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill={index < note ? 'yellow' : 'gray'}
                        className="w-5 h-5"
                    >
                        <path d="M12 17.75l-6.16 3.68 1.67-7.2L2 9.24l7.34-.61L12 2l2.66 6.63 7.34.61-5.51 4.99 1.67 7.2z" />
                    </svg>
                ))}
            </div>
        </div>
    );
};

export default AvisAvecCommentaireEtNotes;
